<div class="content">
    <div class="splash_wrapper">
      <div class="splash_image w-100 bg-image mh-100">
          <app-image src="./assets/images/dog-walk-beach.jpg" cssClasses="img-fluid" [cdn]="true" cdnAssetId="2gG0BKUbdgnipbHQ2CEmM0" cdnParams="?fm=avif&w=2500" [width]="2500" [height]="2895" [priority]="true"></app-image>
        <div class="splash_text_container">
          <div class="heading">
              <h1>Local, trusted dog walking</h1>
              <h4>Book a trusted, local, 5-star dog walker for your furry friend</h4>
          </div>
          <div class="calltoaction">
              <a class="btn btn-primary" [routerLink]="['/contact']" role="button">Book a Walk</a>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="howtobook container-fluid">
        <div class="howtoheading">
            <h2>How booking with us works</h2>
        </div>
        <div class="howtosteps row">
            <div class="col-sm-4">
                <div class="howtostep">
                    <div class="howtostepImage"></div>
                    <div class="howtostepHeading">Step 1: Set your schedule</div>
                    <div class="howtostepText">Available on-demand or pre-scheduled, select the date and time that best suits your pups needs</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="howtostep">
                    <div class="howtostepImage"></div>
                    <div class="howtostepHeading">Step 2: Contact Us</div>
                    <div class="howtostepText">Either by phone, whatsapp or email and we'll confirm your booking</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="howtostep">
                    <div class="howtostepImage"></div>
                    <div class="howtostepHeading">Step 3: Get your confirmation</div>
                    <div class="howtostepText"></div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="reviews">
        <!-- TrustBox widget - Micro Review Count -->
        <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="625fdaf101cc416cdf5060af" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10" data-without-reviews-preferred-string-id="1">
            <a href="https://www.trustpilot.com/review/pawparade.com.au" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
    </div>
</div>
