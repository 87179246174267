// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  contentful : {
    space: 'igheayabqr3n',
    accessToken: 'r1lFMmGru4ab9wrdgGjRyczy-yw8uX8oZyRGFehxdSM',
    contentTypeIds: {
      product: '2PqfXUJwE8qSYKuM0U6w8M',
    },
  },
  googleTagging: {
    enabled: true,
    TAG_MANAGER_ID: 'GTM-W8H4BLX',
    GA_TRACKING_ID: "G-HYQ2NNHEDL"
  },
  api: {
    url: 'https://dev.pawparade.com.au/api'
  },
  facebook: {
    pixelEnabled: false,
    pixelId: 'xxx'
  },
  reCaptcha: {
    siteKey: '6LctzyckAAAAAPzxK_ucjGt6DMjhjRIdl3VY1QC8'
  },
  authentication: {
    clientId: '1sbouspedj5nu7j6i7pbl7vvcm'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
