<div class="content_wrapper card h-100">
    <div *ngIf="content === undefined">
        <div class="card" aria-hidden="true">
            <img class="card-img-top" width="100" height="10" alt="...">
            <div class="card-body">
              <h5 class="card-title placeholder-glow">
                <span class="placeholder col-6"></span>
              </h5>
              <p class="card-text placeholder-glow">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
                <span class="placeholder col-8"></span>
              </p>
              <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
            </div>
        </div>
    </div>
    <ng-container *ngIf="content !== undefined">
        <ng-container *ngIf="content.blockImage">
            <app-image
                    src="{{content.blockImage.fields.file.url}}"
                    cssClasses="card-img-top"
                    altText="{{content.blockImage.fields.title}}"
                    [cdn]="true"
                    [width]="200"
                    [height]="200"
                    cdnParams="?fm=avif&w=200&h=200">
            </app-image>
        </ng-container>
        <div class="content card-body">
            <h5 class="blockTitle card-title">{{content.blockTitle}}</h5>
            <div class="blockText">
                <p *ngFor="let paragraph of content.blockText.content;">{{paragraph.content[0].value}}</p>
            </div>
        </div>
        <div class="blockLink card-footer" *ngIf="content.blockLink"><a class="btn btn-primary" href="{{content.blockLink}}">{{content.blockLinkText}}</a></div>
    </ng-container>
</div>
