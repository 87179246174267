import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactForm } from '../interfaces/contact-form';
import { ConsoleLoggerService } from './console-logger.service';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private localHeaders = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Headers', 'Content-Type, Authorization, X-Requested-With')
  .set('Access-Control-Allow-Methods', 'DELETE, POST, GET, OPTIONS')
  .set('Access-Control-Allow-Origin', '*');


  constructor(
    private http: HttpClient,
    private logger: ConsoleLoggerService  
  ) { }

  convertObjectToDynamoDBInsert(object: any) {
    let uuid = uuidv4();
    this.logger.log(`[API Service] Unique generated ID: ${uuid}`);
    let newObject: any = {item: {
      id: {
        "S": uuidv4()
      }
    }};
    Object.entries(object).map(([key, value], index: number) => {
      if (typeof value === 'string') {
        newObject.item[key] = {
            "S": value
        }
      }
      if (typeof value === 'object') {
        newObject.item[key] = {
            "S": JSON.stringify(value)
        }
      }
    });
    return newObject;
  }

  getApi() {
    return this.http.get(`${environment.api.url}/contact`);
  }

  objectToDynamoDb(object: any) {
    let returnObj: any = {};
    Object.keys(object[0]).map((key) => {
      returnObj[key] = "";
    });
    return returnObj;
  }

  postContact(body: ContactForm) {
    body.submittedDate = formatDate(new Date(), 'long', 'en-GB');
    this.logger.log(`[API Service] Posting content to ${environment.api.url}/contact`, body, this.convertObjectToDynamoDBInsert(body))
    return this.http.post(
      `${environment.api.url}/contact`, 
      this.convertObjectToDynamoDBInsert(body), 
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' 
        })
    });
  }
}
