import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public token: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private log: ConsoleLoggerService
  ) {
    this.token = this.auth.getToken();
  }

  ngOnInit(): void {

    // this.route.queryParams
    //   .subscribe((params: any) => {
    //     if (params.code) {
    //       this.log.info(`[Auth Component] Route uery Parameter code: ${params.code}`);
    //       this.auth.setToken(params.code);
    //       this.auth.setLoggedIn(true);
    //     }
    //   }
    // );

    // this.token.subscribe((t: string) => {
    //   if (t == '') {
    //     //window.open(this.auth.getLoginUrl(), '_self');
    //     window.location.href = this.auth.getLoginUrl();
    //   }
    // });

  }

}
