import { Component, OnInit, isDevMode } from '@angular/core';
import { WhyUsBlock } from 'src/app/interfaces/contentful/why-us-block';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApiService } from 'src/app/services/api.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private contentfulService: ContentfulService,
    private api: ApiService,
    private logger: ConsoleLoggerService,
    public analytics: AnalyticsService
  ) { }

  public content: WhyUsBlock[] | undefined;
  public error: string | undefined;
  public whyUsContentId: string = '11n5c4gbrxqBlVdyHWIqvf';
  public devMode: boolean = false;

  ngOnInit(): void {
    if (!this.content) {
      this.get_why_us_content();
    }
    if (isDevMode()) {
      this.devMode = true;
    }
  }

  get_why_us_content() {
    this.contentfulService.getContentItem(this.whyUsContentId)
    .then(content => {
      this.content = content.fields.whyUsBlock;
      this.logger.log(`[PP] Contentful returned the folowing for ID: ${this.whyUsContentId}`, this.content)
    })
    .catch(error => {
      this.error = "Error: content could not be loaded."
    })
  }

}
