import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ControlContainer, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { CountryData } from 'src/app/interfaces/country-data';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';

@Component({
  selector: 'app-mobile-phone-input',
  templateUrl: './mobile-phone-input.component.html',
  styleUrls: ['./mobile-phone-input.component.scss']
})
export class MobilePhoneInputComponent implements OnInit {
  
  constructor(
    private logger: ConsoleLoggerService,
  ) {}

  public areaCodeSelected: boolean = false;
  public countrySelected!: CountryData;

  @Output() value: EventEmitter<FormControl> = new EventEmitter<FormControl>();

  @Input() parentForm: any = FormGroup;
  @Input() formGroupName!: string;

  public countries: CountryData[] = [
    {
      name: 'Australia',
      iso2: 'au',
      dialCode: '61',
      priority: 0,
      flagImg: 'assets/images/flags/australia-flag-icon-32.png',
      regEx: /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/
    },
    {
      name: 'New Zealand',
      iso2: 'nz',
      dialCode: '64',
      priority: 0,
      flagImg: 'assets/images/flags/new-zealand-flag-icon-32.png'
    },
    {
      name: 'United Kingdom',
      iso2: 'gb',
      dialCode: '44',
      priority: 0,
      flagImg: 'assets/images/flags/united-kingdom-flag-icon-32.png',
      regEx: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
    },
  ];

  ngOnInit(): void {
    this.value.emit(new FormControl(''));
  }

  onAreaCodeClick(areaCode: string) {
    this.areaCodeSelected = true;
    this.countrySelected = this.countries.filter((c: CountryData) => c.iso2 === areaCode)[0];

    //this.areaCodeSelected = `<img src="${countrySelected.flagImg}" /> (+${countrySelected.dialCode})`;
    console.log('Clicked');
  }

  onChange(e: any) {
    const countryPhone = this.countries.filter((c: CountryData) => {
      if (c.regEx && e.target.value.match(c.regEx)) {
        return c;
      } else {
        return false;
      }
    })
    if (countryPhone.length > 0) {
      this.areaCodeSelected = true;
      this.countrySelected = countryPhone[0];
    }
    this.value.emit(e.target.value);
    this.logger.log(`[Mobile-phone-input-component] New value: ${e.target.value}`, countryPhone);
  }
}
