<div *ngIf="content === undefined">
  <app-loading></app-loading>
</div>
<ng-container *ngIf="content !== undefined">
  <div class="content container-fluid">
      <h1>{{content.title}}</h1>
      <div class="accordian" id="accordionExample">
          <div class="accordion-item" *ngFor="let item of content.faq;">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordionExample" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  {{item.fields.question}}
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                <div class="accordion-body">
                  <div *ngFor="let answer of item.fields.answer.content">
                    <div class="answer" *ngFor="let para of answer.content">
                        {{para.value}}
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</ng-container>
