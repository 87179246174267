import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';

// Setup Services
import { ContentfulService } from './services/contentful.service';

// Setup Pages
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { WebsiteTermsComponent } from './pages/website-terms/website-terms.component';
import { DogWalkingComponent } from './pages/services/dog-walking/dog-walking.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { ErrorFourOFourComponent } from './pages/errors/error-four-o-four/error-four-o-four.component';
import { ErrorFiveOTwoComponent } from './pages/errors/error-five-o-two/error-five-o-two.component';
import { ErrorFourOThreeComponent } from './pages/errors/error-four-o-three/error-four-o-three.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';

// Setup Global Components
import { MainmenuComponent } from './components/mainmenu/mainmenu.component';
import { FooterComponent } from './components/footer/footer.component';

// Setup Icons
import { PawComponent } from './components/icons/paw/paw.component';
import { NightIconComponent } from './components/icons/night-icon/night-icon.component';
import { WhistleIconComponent } from './components/icons/whistle-icon/whistle-icon.component';
import { PawLongIconComponent } from './components/icons/paw-long-icon/paw-long-icon.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoComponent } from './components/logo/logo.component';
import { AccountIconComponent } from './components/icons/account-icon/account-icon.component';
import { InstagramIconComponent } from './components/icons/instagram-icon/instagram-icon.component';
import { FacebookIconComponent } from './components/icons/facebook-icon/facebook-icon.component';

// Setup Contenful Components
import { TextComponent } from './components/contentful/text/text.component';
import { CardComponent } from './components/contentful/card/card.component';
import { TestimonialsListComponent } from './components/contentful/testimonials-list/testimonials-list.component';

// Setup 3rd Party imports
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PixelModule } from 'ngx-pixel';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { ApiService } from './services/api.service';
import { SharedModule } from './modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContactSuccessComponent } from './pages/contact-success/contact-success.component';
import { FaqsComponent } from './components/contentful/faqs/faqs.component';
import { PricingInfoComponent } from './components/contentful/pricing-info/pricing-info.component';
import { LinkComponent } from './components/contentful/link/link.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthComponent } from './pages/auth/auth/auth.component';
import { filter } from 'rxjs';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainmenuComponent,
    AboutUsComponent,
    LocationsComponent,
    PawComponent,
    TextComponent,
    FooterComponent,
    LogoComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsAndConditionsComponent,
    WebsiteTermsComponent,
    AccountIconComponent,
    AccountIconComponent,
    DogWalkingComponent,
    NightIconComponent,
    WhistleIconComponent,
    PawLongIconComponent,
    ErrorFourOFourComponent,
    ErrorFiveOTwoComponent,
    ErrorFourOThreeComponent,
    PawComponent,
    AccountIconComponent,
    NightIconComponent,
    WhistleIconComponent,
    PricingComponent,
    InstagramIconComponent,
    FacebookIconComponent,
    TestimonialsComponent,
    TestimonialsListComponent,
    ContactComponent,
    ContactSuccessComponent,
    FaqsComponent,
    PricingInfoComponent,
    LinkComponent,
    LoginComponent,
    AuthComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    PixelModule.forRoot({ enabled: environment.facebook.pixelEnabled, pixelId: environment.facebook.pixelId }),
    NgbNavModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    RecaptchaV3Module,
  ],
  providers: [
    ContentfulService,
    ApiService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptcha.siteKey
    }
  ],
  exports: [
    FormsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
  ) {
    this.router.events
    .pipe(
      // You can also use traditional if else in the subscribe
      filter(event => event instanceof NavigationStart)
    )
    .subscribe(event => {
      console.group(`[Router Logging] Router event: ${event.constructor.name}`);
      console.log(event);
      console.groupEnd();
    });

  }
}
