<div class="loading" *ngIf="!loadedFully" [style.height.px]="height" [style.width.px]="innerWidth"></div>
<img *ngIf="imgSrc && priority"
        src={{imgSrc}}
        class={{cssClasses}}
        height={{_height}}
        width={{_width}}
        alt={{altText}}
        priority
        (load)="loaded()"
/>
<img *ngIf="imgSrc && !priority"
        src={{imgSrc}}
        class={{cssClasses}}
        height={{height}}
        width={{width}}
        alt={{altText}}
        (load)="loaded()"
/>
