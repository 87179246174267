import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-care-icon',
  templateUrl: './day-care-icon.component.html',
  styleUrls: ['./day-care-icon.component.scss']
})
export class DayCareIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
