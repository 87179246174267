import { Component, Input, OnInit } from '@angular/core';
import { IconTextAndList } from 'src/app/interfaces/contentful/icon-text-and-list';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-icon-and-text-list-component',
  templateUrl: './icon-and-text-list-component.component.html',
  styleUrls: ['./icon-and-text-list-component.component.scss']
})
export class IconAndTextListComponentComponent implements OnInit {

  @Input() contentId = '';

  public content: IconTextAndList | undefined;
  public error: string | undefined;

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {

    if (!this.content) {
      this.contentfulService.getContentItem(this.contentId)
      .then(content => {
        console.log(`[PP] Contentful returned the folowing for ID: ${this.contentId}`, content)
        this.content = content.fields;
      })
      .catch(error => {
        this.error = "Error: content could not be loaded."
      });
    }
  }

}
