<div class="content">
  <h1>Login</h1>
</div>
<div>
  <h2>Debug</h2>
  <a href="{{loginUrl}}">Click here to login</a>
  <pre>
    <!-- {{authDetails.AuthenticationResult | json}} -->
  </pre>
</div>
