<div class="container-fluid content">
    <div class="header">
        <div class="header_image">
            <app-image cssClasses="img-fluid" [priority]="true" cdnAssetId="3j36emngaGOij45uY4ziwX" [width]="2216" [height]="832"></app-image>
        </div>
    </div>
    <div class="header_text">
        <div class="row align-items-start">
            <div class="col-lg-3">
                <div class="header_heading">
                    <h1>Our Pricing</h1>
                    <div class="header_call_to_action mb-2">
                        <a class="btn btn-primary" [routerLink]="['/contact']">Book Now</a>
                    </div>
                </div>
            </div>
            <div class="headerIntroText col-lg-9">
                <app-text contentId="1zRchA5zTzA8HYnTQrS6L0"></app-text>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-3">
        <div class="row">
            <div class="content pricingContent">
                <app-pricing-info contentId="Xy79qGYwT7aTunggEP3Bo"></app-pricing-info>
            </div>
        </div>
    </div>
</div>
