import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-care-icon',
  templateUrl: './care-icon.component.html',
  styleUrls: ['./care-icon.component.scss']
})
export class CareIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
