import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { HomeComponent } from './pages/home/home.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { WebsiteTermsComponent } from './pages/website-terms/website-terms.component';
import { ErrorFiveOTwoComponent } from './pages/errors/error-five-o-two/error-five-o-two.component';
import { ErrorFourOFourComponent } from './pages/errors/error-four-o-four/error-four-o-four.component';
import { ErrorFourOThreeComponent } from './pages/errors/error-four-o-three/error-four-o-three.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { ContactSuccessComponent } from './pages/contact-success/contact-success.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthComponent } from './pages/auth/auth/auth.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Home' }},
  { path: 'about', component: AboutUsComponent, data: { title: 'About Us' } },
  {
    path: 'services',
    loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule)
  },
  { path: 'locations', component: LocationsComponent, data: { title: 'Locations' } },
  { path: 'testimonials', component: TestimonialsComponent, data: { title: 'Testimonials' } },
  { path: 'pricing', component: PricingComponent, data: { title: 'Pricing' } },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'cookie-policy', component: CookiePolicyComponent, data: { title: 'Cookie Policy' } },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { title: 'Terms and Conditions' } },
  { path: 'website-terms', component: WebsiteTermsComponent, data: { title: 'Website Terms' } },
  { path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard],
    data: { title: 'Account' }
  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'auth', component: AuthComponent, data: { title: 'Auth' } },
  { path: '403', component: ErrorFourOThreeComponent, data: { title: 'Error 403' } },
  { path: '404', component: ErrorFourOFourComponent, data: { title: 'Error 404' } },
  { path: '502', component: ErrorFiveOTwoComponent, data: { title: 'Error 502' } },
  { path: '**', pathMatch: 'full', component: ErrorFourOFourComponent, data: { title: 'Error 404' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  }
)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
