import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConsoleLoggerService } from './console-logger.service';
import { DocumentService } from './document.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private console: ConsoleLoggerService,
    private doc: DocumentService
  ) { }

  addScriptToHeader() {
    if (environment.googleTagging.enabled === true && (isPlatformBrowser(this.platformId))) {
      // register google tag manager
      const gTagManagerScript = this.doc.getDocument()!.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagging.TAG_MANAGER_ID}`;
      this.doc.getDocument()!.head.appendChild(gTagManagerScript);
  
      // register google analytics
      const gaScript = this.doc.getDocument()!.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${environment.googleTagging.GA_TRACKING_ID}', {
          send_page_view: false
        });
      `;
      this.doc.getDocument()!.head.appendChild(gaScript);
    }
  }

  pageView(url: string, title?: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.console.info(`GA_EVENT: PAGE_VIEW: {page_location: ${url}, title: ${title}}`);
      gtag('event', 'page_view', {
        page_title: title ? title : "",
        page_location: url,
      });   
    }
    
  }

  clickEvent(category: string, label: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.console.info(`GA_EVENT: CLICK: {category: ${category}, label: ${label}, value: ${value}}`);
      gtag('event', 'click', {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  }

  clickButtonEvent(category: string, label: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.console.info(`GA_EVENT: CLICK: {category: ${category}, label: ${label}, value: ${value}}`);
      gtag('event', 'click_button', {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    }
  }
}
