<nav class="navbar navbar-expand-sm fixed-bottom">
    <span class="logo"><app-paw></app-paw></span>
    <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="content !== undefined">
        <li class="nav-item" *ngFor="let footerLink of content;">
            <a class="nav-link" href="{{footerLink.fields.linkUrl}}">
                <ng-container *ngIf="devMode">
                    {{footerLink.fields.linkTitle}}
                </ng-container>
                <ng-container *ngIf="!devMode && footerLink.fields.showInProduction">
                    {{footerLink.fields.linkTitle}}
                </ng-container>
            </a>
        </li>
    </ul>
</nav>