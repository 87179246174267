
<svg xmlns="http://www.w3.org/2000/svg" width="34.014" height="167" viewBox="0 0 34.014 167">
    <g id="FINAL" transform="translate(120 73)">
      <g id="_1_www_walks" data-name="1_www_walks" transform="translate(-120 -73)">
        <g id="Group-6" transform="translate(0 0)">
          <g id="reasons-to-wag" transform="translate(0 0)">
            <path id="Line-4" d="M.153,0V116" transform="translate(17.271 50)" fill="none" stroke="#000000" stroke-linecap="round" stroke-width="2"/>
            <g id="paw">
              <path id="Path" d="M27.581,22.913A7.867,7.867,0,0,1,24.167,20.7a10.783,10.783,0,0,1-1.879-4.083c-.69-2.347-1.353-4.539-5.092-4.539h-.338c-3.758,0-4.4,2.206-5.092,4.539A11.31,11.31,0,0,1,9.887,20.7a7.88,7.88,0,0,1-3.41,2.215c-1.963.8-4.011,1.647-4.011,5.444,0,2.628.77,5.421,6.468,5.557a5.945,5.945,0,0,0,4.227-1.3,5.651,5.651,0,0,1,3.9-1.141,5.616,5.616,0,0,1,3.889,1.141,5.832,5.832,0,0,0,3.941,1.3h.277c5.7-.136,6.463-2.929,6.463-5.557C31.574,24.56,29.54,23.715,27.581,22.913Z" transform="translate(0.008 0.065)" fill="#000000"/>
              <path id="Path-2" data-name="Path" d="M12.887,10.8c2.215-.615,3.4-3.5,2.633-6.458S12.329-.508,10.128.12s-3.4,3.5-2.638,6.458S10.672,11.422,12.887,10.8Z" transform="translate(0.032 0)" fill="#000000"/>
              <path id="Path-3" data-name="Path" d="M21.012,10.8c2.215.629,4.637-1.277,5.4-4.224S26,.735,23.767.12,19.149,1.4,18.384,4.345,18.792,10.179,21.012,10.8Z" transform="translate(0.079 0)" fill="#000000"/>
              <path id="Path-4" data-name="Path" d="M5.637,18.053c2.211-.615,3.4-3.5,2.624-6.463S5.083,6.746,2.868,7.366-.53,10.877.23,13.824,3.422,18.668,5.637,18.053Z" transform="translate(0 0.032)" fill="#000000"/>
              <path id="Path-5" data-name="Path" d="M31.036,7.361c-2.211-.615-4.637,1.272-5.4,4.224s.422,5.848,2.638,6.463,4.628-1.267,5.4-4.224S33.256,7.962,31.036,7.361Z" transform="translate(0.11 0.032)" fill="#000000"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  