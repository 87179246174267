import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DogIconComponent } from 'src/app/components/icons/dog-icon/dog-icon.component';
import { ImageComponent } from 'src/app/components/image/image.component';
import { WaveBgComponent } from 'src/app/components/wave-bg/wave-bg.component';
import { WalkIconComponent } from 'src/app/components/icons/walk-icon/walk-icon.component';
import { DayCareIconComponent } from 'src/app/components/icons/day-care-icon/day-care-icon.component';
import { CareIconComponent } from 'src/app/components/icons/care-icon/care-icon.component';
import { TrustIconComponent } from 'src/app/components/icons/trust-icon/trust-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobilePhoneInputComponent } from '../../components/mobile-phone-input/mobile-phone-input.component';
import { IconAndTextListComponentComponent } from 'src/app/components/contentful/icon-and-text-list-component/icon-and-text-list-component.component';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { CardComponent } from 'src/app/components/contentful/card/card.component';

@NgModule({
  declarations: [
    DogIconComponent,
    WaveBgComponent,
    WalkIconComponent,
    DayCareIconComponent,
    CareIconComponent,
    TrustIconComponent,
    MobilePhoneInputComponent,
    IconAndTextListComponentComponent,
    LoadingComponent,
    CardComponent,
    ImageComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    DogIconComponent,
    WaveBgComponent,
    WalkIconComponent,
    DayCareIconComponent,
    CareIconComponent,
    TrustIconComponent,
    MobilePhoneInputComponent,
    IconAndTextListComponentComponent,
    ReactiveFormsModule,
    FormsModule,
    LoadingComponent,
    CardComponent,
    ImageComponent
  ]
})
export class SharedModule { 
  
}
