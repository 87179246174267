import { Component, OnInit, isDevMode } from '@angular/core';
import { FooterLink } from 'src/app/interfaces/contentful/footer-link';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private contentfulService: ContentfulService,
    private logger: ConsoleLoggerService
  ) { }

  public devMode: boolean = false;

  public contentId: string = '6KY0cc6KhukbPtHXMtKhRC';
  public content: FooterLink[] | undefined;
  public error: string | undefined;

  ngOnInit(): void {
    this.getFooterLinks();
    if (isDevMode()) {
      this.devMode = true;
    }
  }

  getFooterLinks() {
    
    this.contentfulService.getContentItem(this.contentId)
    .then((content) => {
      this.logger.log(`[PP] Contentful returned the folowing for ID: ${this.contentId}`, content)
      this.content = content.fields.footerLink as FooterLink[];
    })
    .catch(error => {
      this.error = "Error: content could not be loaded."
    })
  }

}
