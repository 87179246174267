import { Component, OnInit, isDevMode } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss'],

})
export class MainmenuComponent implements OnInit {

  constructor(
    private doc: DocumentService
  ) { }

  public devMode: boolean = false;

  ngOnInit(): void {
    if (isDevMode()) {
      this.devMode = true;
    }
  }

  toggleMenu() {
    const currentStyle = this.doc.getDocument()!.getElementById("navbarSupportedContent");
    if (currentStyle !== null) {
      if (currentStyle.className === 'mainmenu collapse navbar-collapse') {
        currentStyle.className = 'mainmenu navbar-collapse';
      } 
      else {
        currentStyle.className = 'mainmenu collapse navbar-collapse';
      }
    }
  }
}
