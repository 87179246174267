<div class="content_wrapper">
    <div *ngIf="content === undefined">
        <app-loading></app-loading>
    </div>
    <div class="content" *ngIf="content !== undefined">
        <ng-container *ngFor="let item of content;">
            <h1 *ngIf="item.nodeType === 'heading-1'">{{item.content[0].value}}</h1>
            <h2 *ngIf="item.nodeType === 'heading-2'">{{item.content[0].value}}</h2>
            <h3 *ngIf="item.nodeType === 'heading-3'">{{item.content[0].value}}</h3>
            <ul *ngIf="item.nodeType === 'unordered-list'">
                <li *ngFor="let listItem of item.content">
                  <div *ngFor="let subItem of listItem.content">
                    <span *ngFor="let subItemItem of subItem.content">
                      <b *ngIf="subItemItem.marks.length > 0">{{subItemItem.value}}</b>
                      <ng-container *ngIf="subItemItem.marks.length <= 0">{{subItemItem.value}}</ng-container>
                    </span>
                  </div>
                </li>
            </ul>
            <p *ngIf="item.nodeType === 'paragraph'">{{item.content[0].value}}</p>
            <!-- <div *ngIf="item.nodeType === 'table'">{{item | json}}</div> -->
            <table *ngIf="item.nodeType === 'table'" class="table">
                <ng-container *ngFor="let tableItem of item.content; index as i; first as isFirst">
                    <!-- <div>{{tableItem | json}}<hr></div> -->
                    <ng-container *ngIf="isFirst">
                        <thead>
                            <tr *ngIf="tableItem.nodeType === 'table-row'">
                                <ng-container *ngFor="let tableSubItem of tableItem.content">
                                    <th *ngIf="tableSubItem.nodeType === 'table-cell'" scope="col">
                                        <ng-container *ngFor="let tableSubItemContents of tableSubItem.content">
                                            <ng-container *ngIf="tableSubItemContents.nodeType === 'paragraph'">
                                                <ng-container *ngFor="let tableSubItemContentsSubItem of tableSubItemContents.content">
                                                    <b *ngIf="tableSubItemContentsSubItem.marks.length > 0">{{tableSubItemContentsSubItem.value}}</b>
                                                    <span *ngIf="tableSubItemContentsSubItem.marks.length <= 0">{{tableSubItemContentsSubItem.value}}</span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                    </ng-container>
                </ng-container>
                <tbody class="table-group-divider">
                    <ng-container *ngFor="let tableItem of item.content; index as i; first as isFirst">
                        <tr *ngIf="tableItem.nodeType === 'table-row' && !isFirst">
                            <ng-container *ngFor="let tableSubItem of tableItem.content">
                                <td *ngIf="tableSubItem.nodeType === 'table-cell'">
                                    <ng-container *ngFor="let tableSubItemContents of tableSubItem.content">
                                        <ng-container *ngIf="tableSubItemContents.nodeType === 'paragraph'">
                                            <ng-container *ngFor="let tableSubItemContentsSubItem of tableSubItemContents.content">
                                                <b *ngIf="tableSubItemContentsSubItem.marks.length > 0">{{tableSubItemContentsSubItem.value}}</b>
                                                <span *ngIf="tableSubItemContentsSubItem.marks.length <= 0">{{tableSubItemContentsSubItem.value}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </ng-container>
    </div>
</div>
