import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-four-o-three',
  templateUrl: './error-four-o-three.component.html',
  styleUrls: ['./error-four-o-three.component.scss']
})
export class ErrorFourOThreeComponent implements OnInit {

  // public headersOutput?: { [x: string]: string | null; }[]

  constructor(
    // public headers: HttpHeaders
  ) {

  }

  ngOnInit(): void {
      // this.headersOutput = this.headers.keys().map((key: string) => {
      //   return {
      //     [key]: this.headers.get(key)
      //   }
      // })
  }

}
