<div class="container pb-5 pb-sm-5">
    <div class="form_title">
        <h1>Okay! Before we find your perfect dog walker...</h1>
    </div>
    <div class="alert alert-danger" *ngIf="error !== undefined">{{error}}</div>
    <div class="form contact_form">
        <form #f="ngForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <fieldset>
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <h3><span class="form-step">1</span><b>Step 1:</b> Which services are you interested in...</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-12 services-checkboxes">
                        <div class="form-check form-check-inline services-icon">
                            <input class="form-check-input"
                                type="checkbox"
                                name="servicesrequired"
                                id="servicesrequired1"
                                (change)="updateCheckBox($event)"
                            value="walk">
                            <label class="form-check-label services-check-label" for="servicesrequired1">
                              <div class="services-icon-label">Walking</div>
                              <div class="services-icon-icon"></div>
                            </label>
                        </div>
                        <div class="form-check form-check-inline services-icon">
                            <input class="form-check-input"
                                type="checkbox"
                                name="servicesrequired"
                                id="servicesrequired2"
                                (change)="updateCheckBox($event)"
                                value="doggydaycare">
                            <label class="form-check-label services-check-label" for="servicesrequired2">Doggy Day Care</label>
                        </div>
                        <div class="form-check form-check-inline services-icon">
                            <input class="form-check-input"
                                type="checkbox"
                                name="servicesrequired"
                                id="servicesrequired3"
                                (change)="updateCheckBox($event)"
                                value="overnight">
                            <label class="form-check-label services-check-label" for="servicesrequired3">Overnight</label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="contactForm.get('servicesrequired')?.invalid && contactForm.touched">
                    <div class="col">
                        <div class="text-danger">
                                Please let us know which service you're interested in!
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <h3><span class="form-step">2</span><b>Step 2:</b> Tell us about your pet...</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="form-group">
                            <label>Pet's Name</label>
                            <input
                                type="text"
                                name="petname"
                                formControlName="petname"
                                class="form-control form-control-lg"
                                placeholder="e.g. Simba"
                                required>
                            <div *ngIf="contactForm.get('petname')?.invalid && (contactForm.get('petname')?.touched || contactForm.get('petname')?.dirty)"
                                class="text-danger">
                                Please enter a valid name for your doggo!
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group row mt-sm-4 mt-md-4 mt-lg-0">
                            <div class="col">
                                <label>Pet's est. birthday (dd/mm/yyyy)</label>
                                <input
                                    type="date"
                                    name="petbirthday"
                                    formControlName="petbirthday"
                                    class="form-control form-control-lg"
                                    placeholder="dd/mm/yyyy"
                                    required>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="contactForm.get('petbirthday')?.invalid && (contactForm.get('petbirthday')?.touched || contactForm.get('petbirthday')?.dirty)"
                                class="text-danger">
                                Please enter your pet's birthday so we know how to best care for them...
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 mt-sm-3 mt-lg-0">
                        <div class="form-group">
                            <label>Pet's Weight</label>
                            <input name="petweight"
                                formControlName="petweight"
                                placeholder="e.g. 25kg"
                                class="form-control form-control-lg">
                        </div>
                        <div class="row">
                            <div *ngIf="contactForm.get('petweight')?.invalid && (contactForm.get('petweight')?.touched || contactForm.get('petweight')?.dirty)"
                                class="text-danger">
                                Please enter your pet's weight so we know how to best care for them...
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 mt-sm-3 mt-lg-0">
                        <div class="form-group">
                            <label>Pet's Breed</label>
                            <select name="breed"
                                formControlName="breed"
                                aria-label="Default select example"
                                class="form-select">
                                <option>Affenpinscher</option>
                                <option>Afghan Hound</option>
                                <option>African Hunting Dog</option>
                                <option>Airedale Terrier</option>
                                <option>Akbash Dog</option>
                                <option>Akita</option>
                                <option>Alapaha Blue Blood Bulldog</option>
                                <option>Alaskan Husky</option>
                                <option>Alaskan Malamute</option>
                                <option>American Bulldog</option>
                                <option>American Bully</option>
                                <option>American Eskimo Dog</option>
                                <option>American Eskimo Dog (Miniature)</option>
                                <option>American Foxhound</option>
                                <option>American Pit Bull Terrier</option>
                                <option>American Staffordshire Terrier</option>
                                <option>American Water Spaniel</option>
                                <option>Anatolian Shepherd Dog</option>
                                <option>Appenzeller Sennenhund</option>
                                <option>Australian Cattle Dog</option>
                                <option>Australian Kelpie</option>
                                <option>Australian Shepherd</option>
                                <option>Australian Terrier</option>
                                <option>Azawakh</option>
                                <option>Barbet</option>
                                <option>Basenji</option>
                                <option>Basset Bleu de Gascogne</option>
                                <option>Basset Hound</option>
                                <option>Beagle</option>
                                <option>Bearded Collie</option>
                                <option>Beauceron</option>
                                <option>Bedlington Terrier</option>
                                <option>Belgian Malinois</option>
                                <option>Belgian Tervuren</option>
                                <option>Bernese Mountain Dog</option>
                                <option>Bichon Frise</option>
                                <option>Black and Tan Coonhound</option>
                                <option>Bloodhound</option>
                                <option>Bluetick Coonhound</option>
                                <option>Boerboel</option>
                                <option>Border Collie</option>
                                <option>Border Terrier</option>
                                <option>Boston Terrier</option>
                                <option>Bouvier des Flandres</option>
                                <option>Boxer</option>
                                <option>Boykin Spaniel</option>
                                <option>Bracco Italiano</option>
                                <option>Briard</option>
                                <option>Brittany</option>
                                <option>Bull Terrier</option>
                                <option>Bull Terrier (Miniature)</option>
                                <option>Bullmastiff</option>
                                <option>Cairn Terrier</option>
                                <option>Cane Corso</option>
                                <option>Cardigan Welsh Corgi</option>
                                <option>Catahoula Leopard Dog</option>
                                <option>Caucasian Shepherd (Ovcharka)</option>
                                <option>Cavalier King Charles Spaniel</option>
                                <option>Chesapeake Bay Retriever</option>
                                <option>Chinese Crested</option>
                                <option>Chinese Shar-Pei</option>
                                <option>Chinook</option>
                                <option>Chow Chow</option>
                                <option>Clumber Spaniel</option>
                                <option>Cocker Spaniel</option>
                                <option>Cocker Spaniel (American)</option>
                                <option>Coton de Tulear</option>
                                <option>Dalmatian</option>
                                <option>Doberman Pinscher</option>
                                <option>Dogo Argentino</option>
                                <option>Dutch Shepherd</option>
                                <option>English Setter</option>
                                <option>English Shepherd</option>
                                <option>English Springer Spaniel</option>
                                <option>English Toy Spaniel</option>
                                <option>English Toy Terrier</option>
                                <option>Eurasier</option>
                                <option>Field Spaniel</option>
                                <option>Finnish Lapphund</option>
                                <option>Finnish Spitz</option>
                                <option>French Bulldog</option>
                                <option>German Pinscher</option>
                                <option>German Shepherd Dog</option>
                                <option>German Shorthaired Pointer</option>
                                <option>Giant Schnauzer</option>
                                <option>Glen of Imaal Terrier</option>
                                <option>Golden Retriever</option>
                                <option>Gordon Setter</option>
                                <option>Great Dane</option>
                                <option>Great Pyrenees</option>
                                <option>Greyhound</option>
                                <option>Griffon Bruxellois</option>
                                <option>Harrier</option>
                                <option>Havanese</option>
                                <option>Irish Setter</option>
                                <option>Irish Terrier</option>
                                <option>Irish Wolfhound</option>
                                <option>Italian Greyhound</option>
                                <option>Japanese Chin</option>
                                <option>Japanese Spitz</option>
                                <option>Keeshond</option>
                                <option>Komondor</option>
                                <option>Kooikerhondje</option>
                                <option>Kuvasz</option>
                                <option>Labrador Retriever</option>
                                <option>Lagotto Romagnolo</option>
                                <option>Lancashire Heeler</option>
                                <option>Leonberger</option>
                                <option>Lhasa Apso</option>
                                <option>Maltese</option>
                                <option>Miniature American Shepherd</option>
                                <option>Miniature Pinscher</option>
                                <option>Miniature Schnauzer</option>
                                <option>Newfoundland</option>
                                <option>Norfolk Terrier</option>
                                <option>Norwich Terrier</option>
                                <option>Nova Scotia Duck Tolling Retriever</option>
                                <option>Old English Sheepdog</option>
                                <option>Olde English Bulldogge</option>
                                <option>Papillon</option>
                                <option>Pekingese</option>
                                <option>Pembroke Welsh Corgi</option>
                                <option>Perro de Presa Canario</option>
                                <option>Pharaoh Hound</option>
                                <option>Plott</option>
                                <option>Pomeranian</option>
                                <option>Poodle (Miniature)</option>
                                <option>Poodle (Toy)</option>
                                <option>Pug</option>
                                <option>Puli</option>
                                <option>Pumi</option>
                                <option>Rat Terrier</option>
                                <option>Redbone Coonhound</option>
                                <option>Rhodesian Ridgeback</option>
                                <option>Rottweiler</option>
                                <option>Russian Toy</option>
                                <option>Saint Bernard</option>
                                <option>Saluki</option>
                                <option>Samoyed</option>
                                <option>Schipperke</option>
                                <option>Scottish Deerhound</option>
                                <option>Scottish Terrier</option>
                                <option>Shetland Sheepdog</option>
                                <option>Shiba Inu</option>
                                <option>Shih Tzu</option>
                                <option>Shiloh Shepherd</option>
                                <option>Siberian Husky</option>
                                <option>Silky Terrier</option>
                                <option>Smooth Fox Terrier</option>
                                <option>Soft Coated Wheaten Terrier</option>
                                <option>Spanish Water Dog</option>
                                <option>Spinone Italiano</option>
                                <option>Staffordshire Bull Terrier</option>
                                <option>Standard Schnauzer</option>
                                <option>Swedish Vallhund</option>
                                <option>Thai Ridgeback</option>
                                <option>Tibetan Mastiff</option>
                                <option>Tibetan Spaniel</option>
                                <option>Tibetan Terrier</option>
                                <option>Toy Fox Terrier</option>
                                <option>Treeing Walker Coonhound</option>
                                <option>Vizsla</option>
                                <option>Weimaraner</option>
                                <option>Welsh Springer Spaniel</option>
                                <option>West Highland White Terrier</option>
                                <option>Whippet</option>
                                <option>White Shepherd</option>
                                <option>Wire Fox Terrier</option>
                                <option>Wirehaired Pointing Griffon</option>
                                <option>Wirehaired Vizsla</option>
                                <option>Xoloitzcuintli</option>
                                <option>Yorkshire Terrier</option>
                                <option>Other...</option>
                            </select>
                        </div>
                        <div class="row">
                            <div *ngIf="contactForm.get('breed')?.invalid && (contactForm.get('breed')?.touched || contactForm.get('breed')?.dirty)"
                                class="text-danger">
                                Please enter your pet's breed so we know how to best care for them...
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                        <h3><span class="form-step">3</span><b>Step 3:</b> Tell us about you...</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-lg-3">
                        <div class="form-group">
                            <label>Your First Name</label>
                            <input
                                type="text"
                                name="firstname"
                                formControlName="firstname"
                                class="form-control form-control-lg"
                            required>
                            <div *ngIf="contactForm.get('firstname')?.invalid && (contactForm.get('firstname')?.touched || contactForm.get('firstname')?.dirty)"
                                class="text-danger">
                                Please let us know your email address so we can confirm your contact details!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="form-group">
                            <label>Your Last Name</label>
                            <input
                                type="text"
                                name="lastname"
                                formControlName="lastname"
                                class="form-control form-control-lg"
                            required>
                            <div *ngIf="contactForm.get('lastname')?.invalid && (contactForm.get('lastname')?.touched || contactForm.get('lastname')?.dirty)"
                                class="text-danger">
                                Please let us know your email address so we can confirm your contact details!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Your email address</label>
                            <input
                                type="email"
                                name="email"
                                formControlName="email"
                                class="form-control form-control-lg"
                            required>
                            <div *ngIf="contactForm.get('email')?.invalid && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)"
                                class="text-danger">
                                Please let us know your email address so we can confirm your contact details!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <app-mobile-phone-input [parentForm]="contactForm" (value)="mobileChanges($event)"></app-mobile-phone-input>
                        <div *ngIf="contactForm.get('mobile')?.invalid && (contactForm.get('mobile')?.touched || contactForm.get('mobile')?.dirty)"
                                class="text-danger">
                                Please let us know your mobile number so we can get in touch!
                            </div>
                    </div>
                </div>
            </fieldset>
            <div class="row pb-2 submitButton">
                <div class="col">
                    <button *ngIf="!loading" type="submit" class="btn btn-primary">Get in Touch!</button>
                    <button *ngIf="loading" type="submit" class="btn btn-primary" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
