import { Component } from '@angular/core';

@Component({
  selector: 'app-trust-icon',
  templateUrl: './trust-icon.component.html',
  styleUrls: ['./trust-icon.component.scss']
})
export class TrustIconComponent {

}
