<div *ngIf="content === undefined">
  <app-loading></app-loading>
</div>
<ng-container *ngIf="content !== undefined">
  <div class="content container-fluid">
      <h1>{{content.pricingTitle}}</h1>
      <div class="pricing-info">
        <app-text [contentInput]="content.pricingText.content"></app-text>
      </div>
      <div class="row row-cols-1 row-cols-md-4 g-4">
        <div class="col" *ngFor="let item of content.pricingType;">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">{{item.fields.pricingType}}</h5>
              <app-text [contentInput]="item.fields.pricingInformation.content"></app-text>
            </div>
            <div class="blockLink card-footer" *ngIf="item.fields.link">
              <app-link contentId="{{item.fields.link.sys.id}}"></app-link>
            </div>
          </div>
          </div>
      </div>
  </div>
</ng-container>
