import { HostListener, Injectable } from '@angular/core';
import { Asset, createClient, Entry, EntryCollection } from 'contentful';
import { environment } from 'src/environments/environment';
import { ImageSizes } from '../interfaces/image-sizes';
import { ConsoleLoggerService } from './console-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {

  constructor(private logger: ConsoleLoggerService) { }

  private cdaClient = createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken
  });



  getContentItem(contentItemId: string): Promise<Entry<any>> {
    this.logger.log(`[Contentful Service] Loading content from Contentful for ID: ${contentItemId}`);
    return this.cdaClient.getEntry(contentItemId)
    .then((r: Entry<any>) => {
      this.logger.log(`[Contentful Service] Content loaded! for ${contentItemId}`);
      return r;
    })
    .catch((e: any) => {
      this.logger.error(`[Contentful Service] Error loading from Contentful for ID: ${contentItemId}`);
      return e;
    })
  }

  getAllContentByContentType(contentType: string): Promise<EntryCollection<any>> {
    this.logger.log(`[Contentful Service] Loading entries from Contentful API for Content Type: ${contentType}`);
    return this.cdaClient.getEntries({
      content_type: contentType,
    })
  }

  getImageUrl(assetId: string): Promise<string> {
    this.logger.log(`[Contentful Service] Loading image from Contentful for ID: ${assetId}`);
    return this.cdaClient.getAsset(assetId).then((v: Asset) => {
      return 'https:' + v.fields.file.url;
    });
  }

  
}
