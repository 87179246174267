import { Component, Input, OnInit } from '@angular/core';
import { Card } from 'src/app/interfaces/contentful/card';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  @Input() contentId = '';

  public content: Card | undefined;
  public error: string | undefined;

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit(): void {
    if (!this.content) {
      this.contentfulService.getContentItem(this.contentId)
      .then(content => {
        console.log(`[PP] Contentful returned the folowing for ID: ${this.contentId}`, content)
        this.content = content.fields;
      })
      .catch(error => {
        this.error = "Error: content could not be loaded."
      });
    }
  }

}
