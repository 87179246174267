import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ContentfulService } from 'src/app/services/contentful.service';
import { environment } from 'src/environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginUrl: string = this.createLoginUrl();

  constructor(
    private contentfulService: ContentfulService,
    private api: ApiService,
    private auth: AuthService,
    private logger: ConsoleLoggerService,
    public analytics: AnalyticsService
  ) { }

  ngOnInit(): void {
    //window.location.href = `https://authdev.pawparade.com.au/login?client_id=1h5dml73lsrnqtaboj64oci747&response_type=code&scope=email&redirect_uri=${this.loginUrl}`
    // if (!this.auth.token) {

    // }
    // this.auth.initiateAuth()
    // .then((v) => this.authDetails = v);
    // this.logger.log(`[Login Page] Logging process environment variables`)
  }

  createLoginUrl() {
    return `https://authdev.pawparade.com.au/login?client_id=${environment.authentication.clientId}&response_type=code&scope=email&redirect_uri=${this.encodeUrl(`http://localhost:4200/auth/`)}`;
  }

  encodeUrl(url: string) {
    return encodeURIComponent(url);
  }
}
