import { Component, Input, OnInit } from '@angular/core';
import { RichText } from 'src/app/interfaces/contentful/rich-text';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() contentId: string = '';
  @Input() contentInput!: RichText[];

  public content: RichText[] | undefined;
  public error: string | undefined;

  constructor(
    private contentfulService: ContentfulService,
    private logger: ConsoleLoggerService
  ) { }

  ngOnInit(): void {
    if (this.contentId === '') {
      this.logger.log(`[Text Component] Content ID is BLANK!`, this.contentInput)
    }
    if (this.contentId !== '') {
      if (!this.content) {
        this.contentfulService.getContentItem(this.contentId)
        .then(content => {
          this.logger.log(`[Text Component] Contentful returned the folowing for and Content ID: ${this.contentId}`, content)
          this.content = content.fields.text.content;
        })
        .catch(error => {
          this.error = "Error: content could not be loaded."
        });
      } else {
        this.logger.log(`[Text Component] Content for ID: ${this.contentId} is already loaded!`)
      }
    } else {
      this.content = this.contentInput;
    }
  }

}
