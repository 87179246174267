import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TestimonialList } from 'src/app/interfaces/contentful/testimonial-list';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-testimonials-list',
  templateUrl: './testimonials-list.component.html',
  styleUrls: ['./testimonials-list.component.scss']
})
export class TestimonialsListComponent implements OnInit {

  @Input() contentId = '';

  public content: TestimonialList | undefined;
  public error: string | undefined;

  constructor(
    private contentfulService: ContentfulService,
  ) { }

  ngOnInit(): void {

    if (!this.content) {
      this.contentfulService.getContentItem(this.contentId)
      .then(content => {
        console.log(`[Testimonials List] Contentful returned the folowing for ID: ${this.contentId}`, content)
        this.content = content.fields;
      })
      .catch(error => {
        this.error = "Error: content could not be loaded."
      })
    }
  }
}
