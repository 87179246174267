<div class="container-fluid">
    <div *ngIf="content === undefined">
        <app-loading></app-loading>
    </div>
    <ng-container *ngIf="content !== undefined">
        <div class="title">
            <h1>{{content.title}}</h1>
        </div>
        <div><app-text [contentInput]="content.introduction.content"></app-text></div>
        <div class="container-fluid">
            <div class="row">
                <ng-container *ngFor="let testimonial of content.testemonial">
                    <div class="col-lg-4 col-sm-12">
                        <div class="card">
                            <img [src]="testimonial.fields.testimonialImage.fields.file.url" 
                                class="card-img-top" [alt]="testimonial.fields.testimonialImage.fields.description"
                            />
                            <div class="card-body">
                                <h5 class="card-title">{{testimonial.fields.clientName}}</h5>
                                <div class="card-text">
                                    <div class="d-flex justify-content-center">
                                        <span class="quoteMark align-self-start">&#8220;</span>
                                        
                                        <div class="d-flex">{{testimonial.fields.testimonial}}</div>
                                        <span class="quoteMark align-self-end">&#8221;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>