import { Injectable } from '@angular/core';
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandOutput } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import { ConsoleLoggerService } from './console-logger.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _token = new BehaviorSubject<string>('');
  private _token$ = this._token.asObservable();
  private _loggedIn = new BehaviorSubject<boolean>(false);
  private _loggedIn$ = this._loggedIn.asObservable();

  constructor(
    private logger: ConsoleLoggerService
  ) { }

  public getToken(): Observable<string> {
    return this._token$;
  }

  public setToken(token: string) {
    return this._token.next(token);
  }

  public isLoggedIn() {
    let token = this._token.getValue();
    if (token == '') {
      this.setLoggedIn(false);
    } else {
      this.refreshToken(token);
    }
    return this._loggedIn$;
  }

  public setLoggedIn(state: boolean) {
    return this._loggedIn.next(state);
  }

  getLoginUrl() {
    return `https://authdev.pawparade.com.au/login?client_id=1h5dml73lsrnqtaboj64oci747&response_type=code&scope=email&redirect_uri=${encodeURIComponent(`http://localhost:4200/auth/`)}`;
  }

  public initiateAuth() {
    this.logger.log(`[Auth Service] Initiating Auth sequence...`);
    const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });
    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: { // AuthParametersType
        "USERNAME": "thelastparadox@gmail.com",
        "PASSWORD": "Bobmarley01#01#"
      },
      ClientId: "1h5dml73lsrnqtaboj64oci747", // required
      // UserContextData: { // UserContextDataType
      //   IpAddress: "STRING_VALUE",
      //   EncodedData: "STRING_VALUE",
      // },
    });
    return client.send(command)
    .then((value: InitiateAuthCommandOutput) => {
      this.logger.log(`[Auth Service] Auth successful.`);
      return value;
    })
    .catch((e: Error) => {
      this.logger.log(`[Auth Service] Error from auth command: ${e.message}....`);
      return e;
    })

  }

  public refreshToken(token: string) {
    this.logger.log(`[Auth Service] Refreshing Token sequence...`);
    const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });
    const command = new InitiateAuthCommand({
      AuthFlow: "REFRESH_TOKEN_AUTH",
      AuthParameters: { // AuthParametersType
        "REFRESH_TOKEN": token,
      },
      ClientId: "1h5dml73lsrnqtaboj64oci747", // required
      // UserContextData: { // UserContextDataType
      //   IpAddress: "STRING_VALUE",
      //   EncodedData: "STRING_VALUE",
      // },
    });
    return client.send(command)
    .then((value: InitiateAuthCommandOutput) => {
      this.logger.log(`[Auth Service] Refresh Token successful`, value);
      return value;
    })
    .catch((e: any) => {
      this.logger.log(`[Auth Service] Error from Refresh Token command`, e);
    })

  }

}
