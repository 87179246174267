<div class="form-row">
    <div class="form-row">
        <label>Mobile Number</label>
        <div id="phone"></div>
        <div class="input-group mb-3">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" [innerHTML]="areaCodeSelected ? '(+'+countrySelected.dialCode+')' : 'Area Code'"></button>
            <ul class="dropdown-menu">
                <li *ngFor="let country of countries;">
                    <a class="dropdown-item">
                        <span class="flag-icon"><img width="32" height="16" [src]="country.flagImg"></span>
                        <span (click)="onAreaCodeClick(country.iso2 !== undefined ? country.iso2 : '')">{{country.name}} (&#43;{{country.dialCode}})</span>
                    </a>
                </li>
            </ul>
            <input type="tel" name="mobile" class="form-control" aria-label="Telephone input with dropdown button" (keyup)="onChange($event)">
        </div>
    </div>
</div>