import { Component, OnInit, Input, HostListener, OnChanges } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { ContentfulService } from 'src/app/services/contentful.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ImageSizes } from 'src/app/interfaces/image-sizes';
import { ImageSource } from 'src/app/interfaces/image-source';
import { DocumentService } from 'src/app/services/document.service';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnChanges {

  constructor(
    private contentfulService: ContentfulService,
    private logger: ConsoleLoggerService,
    private doc: DocumentService
  ) { }

  @Input() defaultImage = '';

  @Input() cdn: boolean = true;
  @Input() src: string = '';
  @Input() imageSources: ImageSource[] = [];
  @Input() cssClasses: string = '';
  @Input() altText: string = '';

  @Input() cdnAssetId: string = '';
  @Input() cdnParams: string = '';

  @Input() height!: number;
  public _height!: number;
  @Input() width!: number;
  public _width!: number;

  @Input() priority: boolean = false;

  public type = typeof this.priority;

  public imgSrc!: string;

  public innerWidth!: number;

  public loadedFully: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.recalculateHeight();
  }

  ngOnInit(): void {
    this.logger.log(`[Image Component] Image Component Initialized...`);
    this.recalculateHeight();
  }

  loaded() {
    this.loadedFully = true;
    this.logger.log(`[Image Component] Image fully loaded...`);
  }

  recalculateHeight() {
    this.innerWidth = this.doc.getWindow()!.innerWidth;
    this.logger.log(`[Image Component] Window size: ${this.innerWidth}`);
  }

  ngOnChanges(changes: any) {
    this.logger.log(`[Image Component] Changes detected...`, changes);
    this.recalculateHeight();
    // Use Asset ID to fetch URL from CDN if that is all that is provided
    let imageSize = this.determineResponseImageDimensions({height: this.height, width: this.width});

    if (changes.cdnAssetId !== undefined && changes.cdnAssetId.currentValue !== '') {
      this.contentfulService.getImageUrl(this.cdnAssetId).then((url: string) => {
        this.logger.log(`[Image Component] Setting Image src as asset location: ${url}`);
        this.imgSrc = url+this.amendImageCdnParamaters(this.cdnParams, `&h=${imageSize.height}&w=${imageSize.width}`);
      })
    }
    // Or cycle through sources to determine best image to use based on screen size and layout
    else {
      this.imgSrc = this.src+this.amendImageCdnParamaters(this.cdnParams, `&h=${imageSize.height}&w=${imageSize.width}`);
      //this.imgSrc = this.determineResponsiveImageSource(this.imageSources);
    }
    this.logger.log(`[Image Component] Image Input Dimensions are w:${this._width} h:${this._height}`, changes);
  }

  amendImageCdnParamaters(existingCDNParams: string, additionalParams: string):string {
    if (existingCDNParams.search(/(&w=\d+)/g) > -1) {
      this.logger.log(`[Image Component] Width found in existing CDN Params: ${existingCDNParams}`);
    }
    return "?fm=avif"+additionalParams+"&q=80";
  }

  determineResponsiveImageSource(sources: ImageSource[]): string {
    let responsiveSource: ImageSource[] = sources.filter((source: ImageSource) => source.width <= this.innerWidth);
    return responsiveSource[0].url;
  }

  determineResponseImageDimensions(originalImageSizes: ImageSizes): ImageSizes {
    this.logger.log(`[Image Component] Determining new responsive height and width of image...width: ${originalImageSizes.width}, screeWidth: ${this.innerWidth}`);
    if (originalImageSizes.width > this.innerWidth) {
      let newHeight = Math.ceil(originalImageSizes.height / (originalImageSizes.width / this.innerWidth));
      this._height = newHeight;
      this._width = this.innerWidth;
      this.logger.log(`[Image Component] New responsive height and width of image is are w:${this.innerWidth} h:${newHeight}`);
      return {
        height: newHeight,
        width: this.innerWidth
      }
    } else {
      this._height = originalImageSizes.height;
      this._width = originalImageSizes.width;
      return originalImageSizes;
    }
  }

}
