<div class="splash_wrapper">
    <div class="splash_image w-100 bg-image mh-100">
        <app-image
            src="./assets/images/splash_image.jpg"
            cssClasses="img-fluid"
            [cdn]="true"
            cdnAssetId="13kNdyd4u008CZx0QiPEi3"
            cdnParams="?fm=avif&w=2500&q=80"
            [width]="2500"
            [height]="2895"
            [priority]="true"
        ></app-image>
        <div class="splash_text mask d-flex justify-content-center flex-column text-center">
            <app-text contentId="3RKpJ5PEA1wAvIVlTUyQQH"></app-text>
        </div>
        <div class="tabs_container min-vh-30">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li ngbNavItem class="tab-item">
                <a ngbNavLink><span class="tab-icon"><app-walk-icon></app-walk-icon></span>Walk</a>
                    <ng-template ngbNavContent>
                        <div class="tab-text"><app-text contentId="302stb1mVRCsV37RFbBKf6"></app-text></div>
                        <div><a class="btn btn-primary" [routerLink]="['/contact']" role="button" (click)="analytics.clickButtonEvent('click_button', 'homeSplashServices', 'services - dog walking')">Book Dog Walking</a></div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink><app-day-care-icon class="tab-icon"></app-day-care-icon>Doggy Day Care</a>
                    <ng-template ngbNavContent>
                    <div class="tab-text"><app-text contentId="3Liclu4jQtFKPMu0whyAhz"></app-text></div>
                    <div><a class="btn btn-primary" [routerLink]="['/contact']" role="button">Book Doggy Day Care</a></div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                <a ngbNavLink><app-night-icon class="tab-icon"></app-night-icon>Overnight</a>
                <ng-template ngbNavContent>
                    <div class="tab-text"><app-text contentId="5K0Oh4p0gke521D5qMTe9B"></app-text></div>
                    <div><a class="btn btn-primary" [routerLink]="['/contact']" role="button">Book Overnight Stay</a></div>
                </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
<div class="why-us min-vh-50" *ngIf="content === undefined">
    <app-loading></app-loading>
</div>
<div class="why-us min-vh-50" *ngIf="content !== undefined">
    <div class="why-us-heading"><h1>Why Pawparade</h1></div>
    <ng-container *ngFor="let whyUs of content">
        <div class="why-us-section d-flex p-2">
            <div class="why-us-image">
                <app-image
                  src="https:{{whyUs.fields.whyUsImage.fields.file.url}}"
                  cssClasses=""
                  [cdn]="true"
                  [width]="200"
                  [height]="200"
                  cdnParams="?fm=avif&w=200&h=200"
                ></app-image>
            </div>
            <div class="why-us-divider"><app-paw-long-icon></app-paw-long-icon></div>
            <div class="why-us-text">
                <h2>{{whyUs.fields.whyUsTitle}}</h2>
                <ng-container *ngFor="let item of whyUs.fields.whyUsText.content">
                    <h1 *ngIf="item.nodeType === 'heading-1'" [innerHTML]="item.content[0].value"></h1>
                    <h2 *ngIf="item.nodeType === 'heading-2'" [innerHTML]="item.content[0].value"></h2>
                    <h3 *ngIf="item.nodeType === 'heading-3'" [innerHTML]="item.content[0].value"></h3>
                    <p *ngIf="item.nodeType === 'paragraph'">
                        <span *ngFor="let para of item.content" [innerHTML]="para.value"></span>
                    </p>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
<div class="getstarted d-flex justify-content-center">
    <button class="btn btn-primary btn-lg" [routerLink]="['/contact']" gaEvent="click_button" gaCategory="engagement" gaLabel="home_get_started">Get Started</button>
</div>
<div class="wave-bg">
    <app-wave-bg></app-wave-bg>
</div>
<div class="services min-vh-50">
    <div class="services_wrapper row">
        <div class="services_block col-lg-3 col-md-6">
            <app-card contentId="6ERhemhX0df8AWZA4LPZHJ"></app-card>
        </div>
        <div class="services_block col-lg-3 col-md-6">
            <app-card contentId="2VlkaiOEz2FZvNccFZMMKo"></app-card>
        </div>
        <div class="services_block col-lg-3 col-md-6">
            <app-card contentId="3hAPGwIeIs5ranItQfOqwa"></app-card>
        </div>
        <div class="services_block col-lg-3 col-md-6">
            <app-card contentId="59yyxlHUzVWg4UdoY1j8We"></app-card>
        </div>
    </div>
</div>
<hr class="bottom_hr">
<div class="reviews">
    <!-- TrustBox widget - Micro Review Count -->
    <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="625fdaf101cc416cdf5060af" data-style-height="24px" data-style-width="100%" data-theme="light" data-min-review-count="10" data-without-reviews-preferred-string-id="1">
        <a href="https://www.trustpilot.com/review/pawparade.com.au" target="_blank" rel="noopener">Trustpilot</a>
    </div>
    <!-- End TrustBox widget -->
</div>
