import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, skip } from 'rxjs';
import { AnalyticsService } from './services/analytics.service';
import { DocumentService } from './services/document.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private analytics: AnalyticsService,
    private doc: DocumentService
  ) { }

  ngOnInit() {
    this.analytics.addScriptToHeader();
    this.setGAPageTag();

  }

  setGAPageTag() {
    // Setup GA Page Tracking
    this.router.events
    .pipe(
      skip(1),
      // takeUntil(this.ngOnDestroy$),
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    )
    .subscribe((e: NavigationEnd) => {
      let route: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';
      while (route!.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        routeTitle = route!.snapshot.data['title'];
      }
      // this.logger.log(`Page is equal to ${e.urlAfterRedirects} and name: ${routeTitle}`);
      this.analytics.pageView(e.urlAfterRedirects, routeTitle);
    });
  }
}
