import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ContactForm } from 'src/app/interfaces/contact-form';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ContactFormResponse } from 'src/app/interfaces/contact-form-response';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})

export class ContactComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private logger: ConsoleLoggerService,
    private api: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
  ) { }

  public contactForm: FormGroup = this.fb.group({
    servicesrequired: this.fb.array([], [
      Validators.required,
      Validators.minLength(1)
    ]),
    petname: ['', [
      Validators.required,
      Validators.minLength(4)
    ]],
    petbirthday: ['', Validators.required],
    petweight: ['', Validators.required],
    breed: ['', Validators.required],
    firstname: ['', [
      Validators.required,
      Validators.minLength(3)
    ]],
    lastname: ['', [
      Validators.required,
      Validators.minLength(3)
    ]],
    email: ['', [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]],
    mobile: ['', [
      Validators.required,
      Validators.minLength(3)
    ]],
  });

  public error!: string;
  public captchaVerified: boolean = false;
  public siteKey: string = environment.reCaptcha.siteKey;
  public loading: boolean = false;

  private contactFormSubmitSubscription!: Subscription;

  ngOnInit(): void {
    if (isDevMode()) {
      //this.contactTest();
    }
  }

  mobileChanges(formControl: FormControl) {
    this.logger.log(`[Contact Page] Received value from mobile component...`, formControl);
    this.contactForm.controls['mobile'].setValue(formControl);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);

  }

  updateCheckBox(e: any) {
    const servicesRequired: FormArray = this.contactForm.get('servicesrequired') as FormArray;
    if (e.target.checked) {
      servicesRequired.push(new FormControl(e.target.value));
    } else {
      servicesRequired.controls.map((f: AbstractControl, i: number) => {
        if (f.value == e.target.value) {
          servicesRequired.removeAt(i);
          return;
        }
      })
    }
    this.logger.log(`[Contact Component] Form Value:`, e, this.contactForm.value);
  }

  submitForm(contactForm: ContactForm) {
    this.logger.log(`[Contact Page] Preparing the following for data submission...`, contactForm);
    // this.recaptchaV3Service.execute('login')
    // .subscribe((token) => {
    //     this.logger.log(`[Contact Component] ReCaptcha Token: ${token}`);
    // });
    this.contactFormSubmitSubscription = this.api.postContact(contactForm)
    .subscribe({
      next: ((v: any) => {
        this.logger.log(`[Contact Page] Result from form submission...`, v);
      }),
      complete: (() => {
        this.router.navigate(['/contact/success']);
        this.loading = false;
      }),
      error: ((e: Error) => {
        this.logger.error(`[Contact Page] Error from form submission...`, e);
        this.error = "Something went wrong with the form...";
        this.loading = false;
      })
    });
  }

  onSubmit() {
    this.loading = true;
    this.logger.warn(`[Contact Page] Submitted form is ${this.contactForm.status}...`, this.contactForm);
    this.contactForm.markAllAsTouched();
    if (this.contactForm.status === "VALID") {
      this.submitForm(this.contactForm.value);
    } else {
      this.loading = false;
    }
  }

  public ngOnDestroy() {
    //this.contactFormSubmitSubscription.unsubscribe();
  }

}
