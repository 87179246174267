<div class="container-fluid">
    <div class="content_wrapper row">
        <div class="aboutUsImage col-sm-3 col-md-4 col-lg-4 order-1 order-lg-1 order-xs-3">
            <div class="image">
                <app-image src="./assets/images/AboutUs3.jpg" cdnAssetId="4XNPiv5lpjtJs2Xm5GCQf5" cssClasses="img-fluid" [width]="526" [height]="669"></app-image>
            </div>
            <div class="image d-none-sm">
                <app-image src="./assets/images/AboutUs.jpg" cdnAssetId="5eN8KaTrrXkXuDJiDvGC5Z" cssClasses="img-fluid" [width]="526" [height]="701"></app-image>
            </div>
        </div>
        <div class="aboutUsText col-sm-3 col-md-4 col-lg-4 order-lg-2 order-xs-1">
            <div class="strapLine">
                <div class="strapLineDivider"></div>
                <app-text contentId="48RaRrWzo4TGPXV4VAjzG4"></app-text>
                <div class="strapLineDivider"></div>
            </div>
            <app-text contentId="3cHn78NiwIRLiUSMGB6xqo"></app-text>
        </div>
        <div class="col-sm-3 col-md-4 col-lg-4 order-lg-3 order-xs-2">
            <div class="video">
                <iframe width="333" height="592" src="https://www.youtube.com/embed/CxJBeo1UOeA?&playlist=CxJBeo1UOeA&controls=0" title="Paw Parade Sunshine Coast - Dog fun!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
