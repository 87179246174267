import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-five-o-two',
  templateUrl: './error-five-o-two.component.html',
  styleUrls: ['./error-five-o-two.component.scss']
})
export class ErrorFiveOTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
