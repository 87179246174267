<div *ngIf="content === undefined">
    <app-loading></app-loading>
</div>
<ng-container *ngIf="content !== undefined">
    <div class="content container-fluid">
        <h1>{{content.title}}</h1>
        <div class="row mb-3" *ngFor="let item of content.list;">
            <div class="icon col-2 ms-2 me-2 mt-1">
                <app-image [src]="item.fields.icon.fields.file.url" cssClasses="img-fluid" [width]="90" [height]="90"></app-image>
            </div> 
            <div class="col-9">
                <div class="title"><h5>{{item.fields.title}}</h5></div>
                <div class="description">{{item.fields.description}}</div>
            </div>
        </div>
    </div>
</ng-container>