<div class="navbar navbar-expand-lg navbar-dark">
    <button class="navbar-toggler navbar-toggle"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            (click)="toggleMenu()"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <span class="logo">
        <a [routerLink]="'/'">
            <app-image cdnAssetId="5VHazMLK1PhxHJQM4Ofnbp" [height]="30" [width]="30"></app-image>
        </a>
    </span>
    <span><a class="navbar-brand brand-name" [routerLink]="'/'">PawParade</a></span>
    <div class="mainmenu collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
                <a class="nav-link" aria-current="page" [routerLink]="['/']" (click)="toggleMenu()">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" aria-current="page" [routerLink]="['/about']" (click)="toggleMenu()">About Us</a>
            </li>
            <li class="nav-item" *ngIf="devMode === true">
                <a class="nav-link" aria-current="page" [routerLink]="['/services']" (click)="toggleMenu()">Our Services</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" aria-current="page" [routerLink]="['/pricing']" (click)="toggleMenu()">Pricing</a>
            </li>
            <li class="nav-item" *ngIf="devMode === true">
                <a class="nav-link" aria-current="page" [routerLink]="['/testimonials']" (click)="toggleMenu()">Testimonials</a>
            </li>
            <li class="nav-item" *ngIf="devMode === true">
              <a class="nav-link" aria-current="page" [routerLink]="['/account']" (click)="toggleMenu()">My Account</a>
            </li>
            <li class="nav-item" *ngIf="devMode === true">
              <a class="nav-link" aria-current="page" [routerLink]="['/auth']" (click)="toggleMenu()">Auth</a>
            </li>
          <li class="nav-item">
                <a class="nav-link btn btn-mainmenu" aria-current="page" [routerLink]="['/contact']" (click)="toggleMenu()">Get in Touch</a>
            </li>
        </ul>
        <div class="socialnav" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0 mr-md-10 d-flex flex-row">
                <li class="nav-item align-middle">
                    <a class="nav-link" href="https://instagram.com/pawparade.qld" target="_blank">
                        <app-instagram-icon></app-instagram-icon>
                    </a>
                </li>
                <li class="nav-item align-middle ml-10">
                    <a class="nav-link" href="https://www.facebook.com/PawParade.qld" target="_blank">
                        <app-facebook-icon></app-facebook-icon>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
